<template>
  <a-modal
    v-model="show"
    title="企业新闻详情"
    :centered="true"
    :keyboard="false"
    :footer="null"
    :width="900"
    @cancel="handleCancleClick"
  >
    <a-row>
      <a-col :span="12">
        <p><my-icon type="news"/>新闻标题：{{form.title ? form.title : '暂无'}}</p>
        <p><my-icon type="sponsor"/>创建人员：{{form.createUsername ? form.createUsername : '暂无'}}</p>
        <p><a-icon type="clock-circle"/>创建时间：{{form.createTime ? form.createTime : '暂无'}}</p>
      </a-col>
      <a-col :span="1"></a-col>
      <a-col :span="11">
        <p><my-icon type="browse"/>浏览人数：{{ form.viewNumbers ? `${form.viewNumbers} 人` : '暂无' }}</p>
        <p><my-icon type="commend"/>点赞人数：{{ form.target ? `${form.target} 人` : '暂无' }}</p>
      </a-col>
    </a-row>
		<a-row style="margin-bottom: 10px" >
			<a-col :span="5">
				<span><my-icon type="news"/>封面：</span><br/>
				<img class="cover-image" :src="src" title="新闻封面" alt="新闻封面" />
			</a-col>
			<a-col :span="1">
			</a-col>
		  <a-col :span="18">
		    <span><my-icon type="news"/>新闻：</span><br/>
		    <span class="info" v-html="form.news ? form.news : '暂无'"></span>
		  </a-col>
		</a-row>
  </a-modal>
</template>

<script>
export default {
  name: "NewsInfo",
  components: {

  },
  props: {
    infoVisible: {
      required: true,
      type: Boolean
    },
  },
  data() {
    return {
      form: {
        title: undefined
      }
    }
  },
  computed: {
    show: {
      get: function() {
        return this.infoVisible;
      },
      set: function() {}
    },
    range: {
      get: function() {
        return this.form.range?"内部":"外部";
      }
    },
		src: {
			get: function() {
				return this.form.image?`${this.$store.state.baseinfo.filedomain}${this.form.image}`:"";
			}
		}
  },
  methods: {
    handleCancleClick() {
      this.$emit("_close");
    },
    setFormFields({...info}) {
      this.form = info;
    }
  }
}
</script>

<style lang="less" scoped>
.info {
  display: block;
  max-width: unset;
  font-size: 14px;
  line-height: 25px;
  color: gray;
  margin-top: 10px;
  padding: 8px;
  background: #fcfcfc;
  border: 1px #ccc dotted;
  white-space: normal;
}
.cover-image {
	margin-top: 10px;
	width: 100%;
	// height: 120px;
}
i {
  margin-right: .8rem;
}
</style>
